<template>
  <section class="delivery-info">
    <FormInfo gap="10" class="order-info info">
      <FormInfoItem width="120" title="運送方式" :content="delivery.name" />
      <FormInfoItem width="120" title="運送狀態" class="flex">
        <div class="flex justify-start items-center" style="width: 100%;">
          <BaseElSelect
            :value="status"
            class="status-select"
            placeholder="選擇狀態"
            :disabled="disabledSelectStatus"
            @input="onChangeStatus"
          >
            <BaseElSelectOption
              v-for="opt in statusOptions"
              :key="opt.value"
              :label="opt.name"
              :value="opt.value"
              :disabled="opt.disabled"
            />
          </BaseElSelect>
          <span v-show="showDownloadLink" class="download-link" @click="onDownload">
            下載寄件單
          </span>
        </div>
      </FormInfoItem>
      <FormInfoItem width="120" title="收件人姓名" :content="delivery.receiver" />
      <FormInfoItem v-if="showStoreName" width="120" title="收件門市" :content="storeName || '-'" />

      <FormInfoItem
        title="取貨編號"
        width="120"
        :content="order.takeNo || '-'"
      />
      <FormInfoItem
        title="取貨門市"
        width="120"
        :content="delivery.storeName || '-'"
      />
    </FormInfo>
    <FormInfo gap="10" class="order-info info">
      <FormInfoItem
        width="120"
        title="運送編號"
        :content="shippNo"
      />
      <FormInfoItem width="120" title="運送狀態說明" :content="delivery.statusMsg || '-'" />
      <FormInfoItem width="120" title="收件人電話" :content="delivery.phone" />

      <FormInfoItem
        title="收件人地址"
        width="120"
        :content="delivery.address || '-'"
      />
    </FormInfo>
  </section>
</template>

<script>
import FormInfo from '@/components/FormInfo/FormInfo.vue'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'
import {
  orderDeliveryStatus,
  orderDeliveryStatusList,
  deliveryConfigOptions,
  deliveryTypesConfig,
} from '@/config/ecommerce'
export default {
  name: 'DeliveryInfo',
  components: {
    FormInfo,
    FormInfoItem,
  },
  props: {
    order: { type: Object },
    delivery: { type: Object },
    status: { type: String },
    orderCancel: { type: Boolean },
  },
  data () {
    return {
      deliveryTypes: {
        ...deliveryConfigOptions,
        ...deliveryTypesConfig,
      },
    }
  },
  computed: {
    disabledSelectStatus () {
      if (this.orderCancel) return true
      return [orderDeliveryStatus.cancel, orderDeliveryStatus.completed].includes(this.status)
    },
    isUseECPay () {
      const { type } = this.delivery
      return type === this.deliveryTypes.ecpay
    },
    showDownloadLink () {
      if (!this.isUseECPay) return false
      return [orderDeliveryStatus.tallying].includes(this.status)
    },
    statusOptions () {
      const { wait, tallying, shipped, completed, returned } = orderDeliveryStatus
      const mapAvailableStatus = {
        [wait]: [tallying, shipped, completed],
        [tallying]: [shipped, completed],
        [shipped]: [completed, returned],
        [completed]: [returned],
      }
      const availableStatus = mapAvailableStatus[this.status] || []
      return orderDeliveryStatusList.map(item => ({ ...item, disabled: !availableStatus.includes(item.value) }))
    },
    showStoreName () {
      return this.isUseECPay
    },
    showStoreAddress () {
      return this.isUseECPay
    },
    storeName () {
      const { storeID, storeName } = this.delivery
      if (!storeID) return ''
      return `${storeID} ${storeName || ''}`
    },
    shippNo () {
      const { code, validationNo } = this.delivery
      if (!code) return '未新增'
      if (!validationNo) return code
      // 7-11 店到店會有驗證碼
      return `${code}（驗證碼：${validationNo}）`
    },
  },
  methods: {
    onChangeStatus (value) {
      this.$emit('update:status', value)
    },
    onDownload () {
      this.$emit('donwload')
    },
  },
}
</script>

<style lang="scss">
.delivery-info {
  .order-info {
    @apply w-full py-[27.5px] px-[20px] bg-white;
    background: white;

    .item {
      margin-bottom: 15.5px !important;
    }
  }
  .download-link {
    @apply text-primary-100 underline font-medium text-sm ml-[12px] leading-[2] cursor-pointer;
  }
}
</style>
