<template>
  <el-dialog
    title="提示"
    width="400"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div>
      是否確定取消訂單？
    </div>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" @click="confirm">確定</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { CancelOrder } from '@/api/ecommerce/order'
import { mapGetters } from 'vuex'
export default {
  name: 'InvoiceCancelDialog',
  computed: {
    ...mapGetters(['shop']),
  },
  methods: {
    confirm () {
      this.$emit('cancelOrder')
      this.$emit('close')
    },

    async cancelOrder () {
      const [res, err] = await CancelOrder({
        shopId: this.shop,
      })
      if (err) return this.$message.error(err)
      this.$message.warning('已取消該訂單！')
      console.log(res)
    },
  },
}
</script>

<style></style>
