<template>
  <div class="order-info-block">
    <div class="_header">
      <div class="_title">{{ title }}</div>
      <div class="flex">
        <BaseElButton v-if="btn2 && !hideBtn" class="underline" :disabled="btn2Disabled" type="text">{{ btn2 }}</BaseElButton>
        <BaseElButton v-if="!hideBtn" class="underline" :disabled="btnDisabled" type="text" @click="$emit('edit')">編輯</BaseElButton>
      </div>
    </div>
    <div class="_body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderInfoBlock',
  props: {
    hideBtn: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    btn2: {
      type: String,
      default: '',
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    btn2Disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="postcss" scoped>
.order-info-block {
  @apply flex flex-col bg-white rounded-sm mb-[16px];
}

._header {
  @apply flex justify-between items-center py-[12px] px-[16px] ;
  @apply text-lg text-primary-100;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

._title {
  @apply font-medium;
}
</style>
