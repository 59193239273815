<template>
  <EcommerceOrderDetailWrapper :config="config" />
</template>
<script>
import EcommerceOrderDetailWrapper from './EcommerceOrderDetailWrapper.vue'
import { FindOrder } from '@/api/ecommerce/order'
import { GetConfig } from '@/api/ecommerce/paramter'
import { useCustomFlow } from '@/use/useCustomFlow'
import { mapGetters } from 'vuex'

export default {
  name: 'ECOrderDetail',
  components: {
    EcommerceOrderDetailWrapper,
  },
  setup () {
    const { useCustomFlowFeature } = useCustomFlow()
    return { useCustomFlowFeature }
  },
  data: () => ({
    order: '',
    loading: false,
    config: '',
  }),
  computed: {
    ...mapGetters(['shop']),

    invoice () {
      if (this.order === '' || !this.order.EcommerceOrderInvoice) return {}
      return this.order.EcommerceOrderInvoice
    },
    delivery () {
      if (this.order === '' || !this.order.EcommerceOrderDelivery) return {}
      return this.order.EcommerceOrderDelivery
    },

    orderPaymentConfig () {
      return this.order.EcommercePaymentRecord.EcommercePaymentConfig || null
    },

    routeQueryShopId () {
      return this.$route.query.shopIdFromAdmin
    },
  },

  async mounted () {
    await this.checkRouteQueryShopIdFromAdmin()
    await this.refresh()
  },

  methods: {
    async refresh () {
      const [res, error] = await FindOrder({
        shopId: this.shop,
        id: this.$route.params.id,
        includeCustomFlowRecord: this.useCustomFlowFeature.record,
      })

      if (error) return this.$message.error(error)
      this.order = res

      await this.getConfig()
    },

    async getConfig () {
      const [res, error] = await GetConfig({
        shopId: this.shop,
      })
      if (error) return this.$message.error(error)
      this.config = res
    },

    async checkRouteQueryShopIdFromAdmin () {
      if (this.routeQueryShopId) {
        this.$store.commit('user/SET_NOW_SHOP', this.routeQueryShopId)
        await this.$store.dispatch('user/getMe')
      }
    },
  },
}
</script>
