<template>
  <el-dialog
    title="是否自動開立發票？"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div>
      <p>選擇 “是”</p>
      <p>發票將自動開立並由系統商發送</p>
    </div>
    <div>
      <p>選擇 “否”</p>
      <p>則需手動調整發票狀態開立</p>
    </div>

    <div slot="footer">
      <BaseElButton plain @click="cancel">否</BaseElButton>
      <BaseElButton type="primary" @click="confirm">是</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AutoInvoiceDialog',
  methods: {
    confirm () {
      this.$emit('confirm')
      this.$emit('close')
    },

    cancel () {
      this.$emit('cancel')
      this.$emit('close')
    },
  },
}
</script>

<style></style>
